@import './common.scss';

.content {
    max-width: 1200px;
    margin: auto;
}

.ant-layout-header {
    height: 85px;
    background: #1A1F24;
}

.ant-menu-dark, .ant-menu-dark>.ant-menu {
    color: rgba(255, 255, 255, 0.65);
    background: #1A1F24;
}

body {
    font-family: 'Roboto' !important;
    margin: 0;
}

.divider {
    color: #5D5E5F;
}

.footer {
    color: #fff;
    background: #1A1F24;
    margin-top: 60px;
}

.content-footer {
    max-width: 1200px;
    margin: auto;
}

.logo-menu-container {
    display: flex;
    align-items: center;
    margin: auto;
    max-width: 1200px;
}

.logo {
    margin-right: 564px;
    margin-top: 3px;
}

.ant-menu-item-active {
    border-bottom: 2px solid rgb(255, 255, 255) !important;
    padding-bottom: 15px;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected:hover, 
.ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected:hover, 
.ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected:hover, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected:hover {
    background-color: #1A1F24;
}

.ant-layout-header {
    line-height: 0; 
}

.language-text {
    font-size: 16px;
    margin-right: 4px;
    color: grey;
}
  
.language-text.selected {
    color: white; 
}
  
.divider {
    font-size: 16px;
    margin: 0 4px;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected {
    color: #fff;
    background-color: #1A1F24;
}

.menu-icon-circle {
    //padding-left: 100px;

}

.linea-horizontral {
    border-bottom: solid 1px #262B30;
    width: 100%;
    margin-top: 15px;
    display: flex;
}
.footer-margin {

}
.link-catalogo {
    margin-left: -43px
}
.link-contacto {
    margin-left: 8px
}
.redes-sociales {
    padding-left: 84px !important;
}

.footer-button {
    color: #06EFFE; 
    font-weight: bold; 
    margin-left: -15px;
}