@media screen and (min-width: 768px) and (max-width: 1024px) {
    .posicionamiento {
        position: absolute; 
        bottom: 13px;
        right: 280px;
    }
    .detalle-card {
        text-align: left; 
        margin-bottom: 20px;
    }
    .titulo-card {
        margin-top: -30px !important;
    }
    .link-catalogo {
        margin-left: -40px;
    } 
    .margin-beneficio {
        text-align: center; 
        //margin-right: 20px; 
        margin-left: 70px;
    }
}