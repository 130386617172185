.detalle-catalogo{
    max-width: 1200px;
    margin: auto;
}
.img-detalle {
    background-image: url('../images/fondo_2.svg'); 
    background-repeat: no-repeat;
    background-position: bottom;
    margin-bottom: 10px;
    background-position: 50% 15%
}

.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card >div>.ant-tabs-nav .ant-tabs-tab-active {
    color: #fff !important;
    background: #0E8DA6;
}
.ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top >div>.ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #0E8DA6 !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
    text-shadow: 0 0 0.25px currentcolor;
    font-weight: bold;
}

.ant-table-wrapper .ant-table {
    color: rgb(255 255 255);
    list-style: none;
    font-family:  Roboto !important ;
    background: rgba(0, 21, 21, 0.80);
}

.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
    position: relative;
    color: #fff;
    font-weight: 600;
    text-align: start;
    background: rgb(0, 21, 21, 0.80);
    border-bottom: 1px solid #262B30;
}

.ant-table-wrapper .ant-table-tbody >tr >th, .ant-table-wrapper .ant-table-tbody >tr >td {
    border-bottom: 1px solid #262B30;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover{
    background: transparent;
}

.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab, .ant-tabs-card >div>.ant-tabs-nav .ant-tabs-tab {
    border: 1px solid #0E8DA6;
}

.card-drescripcion {
    .ant-card .ant-card-body {
        border: 2px solid #0E8DA6 !important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: rgba(36, 121, 121, -0.76) !important;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
    }
}

.card-detalle {
    .ant-card .ant-card-body {
        padding: 0 !important;
        border: 2px solid #0E8DA6 !important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: rgba(36, 121, 121, -0.76) !important;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
    }
}
.card-descripcion {
    margin-top: -17px;
}

.card-descripcion {
    margin-top: -17px;
    background: #03222a3b; 
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}
.card-detalle-table {
    background: #03222a3b; 
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}
.divDescripcion {
    width: 130px;
    text-align: center;
}

.ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top >div>.ant-tabs-nav .ant-tabs-tab {
    border-radius: 16px 16px 0 0;
}

.descripcion-detalle {
    padding-left: 35px !important;
}
.tab-descripcion {
    padding-left: 35px;
    padding-right: 44px;
}
.tab-esquema { 
    padding-right: 42px;
    padding-left: 36px;
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #f0f0f000;
}