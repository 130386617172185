@import './common.scss';

.content-contacto {
    background-image: url('../images/contact.png'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 60vh;
}

.contacto-img {
    max-width: 1200px;
    margin: auto;
}

.formulario-contacto{
    max-width: 1200px;
    margin: auto;
}

.details-mobile {
    text-align: center;
}

.card-formulario {
    border: 1px solid #0E8DA6 !important;
    color: $text1 !important;
    font-weight: $weight9 !important;
    font-size: 20px;
}

.ant-card-bordered {
    border: 0;
}

.ant-input-outlined {
    background: rgba(28, 95, 132, 0.08);
    border-width: 0;
    color: $text1 !important;
    border-radius: 30px;
}

.ant-input-outlined:focus,.ant-input-outlined:focus-within {
    outline: 0;
    background-color: rgba(28, 95, 132, 0.08);
    border-color: rgba(28, 95, 132, 0.08);;
    box-shadow: 0 0 0 2px rgba(28, 95, 132, 0.08);
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    background: rgba(28, 95, 132, 0.08);
    border-radius: 30px;
}

.ant-form-item .ant-form-item-label >label {
    color: $text1;
    font-weight: $weight7;
    font-size: 14px;
}

.card-faq {
    border-width: 1px;
    .ant-card-body {
        padding: 10px;
    }
    border-color: #0E8DA6;
    background-color: rgba(14, 141, 166, 0.25) !important;
}

.content-form {
    max-width: 1200px;
    margin: auto;
}

.select-form {
    .ant-select-dropdown {
        background-color:  #02111A;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
    }
    &.ant-select-single .ant-select-selector {
        color: #ffffff !important;
    }

    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: rgb(255 252 252);
    }

}

.ant-btn-primary:disabled, .ant-btn-primary.ant-btn-disabled {
    border-color: #262B30;
    color: #5B6770;
    background: #262B30;
}

.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #021720;
}

.ant-input::placeholder {
    color: #C3C3C3;
}

.ant-select .ant-select-selection-placeholder {
    color: #C3C3C3;
    font-weight: 400;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: #1C5F84;
    border-color: #ff4d4f;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus, 
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus-within {
    border-color: #1C5F84;
    background-color: #1C5F84;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 12px;
}

.ant-input:placeholder-shown {
    font-size: 13px;
}

.ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: 0 1px 0 1px #001617;
}

.details-mobile {
    margin-top: 80px;
}

.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-inline-end: -7px;
}

 