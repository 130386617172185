
.content-layout {
    background-image: url('../images/fondo-slider.png'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 90vh;
    padding: .1px;
}

/*.card-medio-content {
    max-width: 1200px;
    margin: auto;
}*/

.maxwidth-cards{
    max-width: 1200px;
    margin: auto;
}

.maxwidth-video {
    max-width: 1200px;
    margin: auto;
}

.maxwidth-beneficios {
    max-width: 1200px;
    margin: auto;
}

.tarjetascaracteristicas {
    padding-left: 22px;
    padding-right: 16px;
    position: relative;
    margin: auto;
    margin-top: -102px;
    will-change: transform;
}

/*.cards-caracteristicas {
    background-color: #03222A;
    color: #fff;
    width: 320px;
    height: 370px;
}*/

.cards-caracteristicas {
    //border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(10px);
    width: 320px;
    height: 370px;
}

.textos-layotu {
    margin-top: 40px;
    color: #fff;
    margin-left: 23px;
}

.text-layout {
    max-width: 1200px;
    margin: auto;
    margin-top: 50px;
}

.posicionamiento {
    position: absolute; 
    bottom: 18px;
    right: 485px;
}
.detalle-card {
    text-align: left; 
    margin-bottom: 10px;
}
.img-inicio {
    background-image: url('../images/fondo_2.svg'); 
    background-repeat: no-repeat;
    background-position: 50% 75%;
}
.card-carrousel {
    background: #03222a3b; 
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}

.video {
    margin-top: 120px;
    margin-left: 17px;
    margin-right: 126px;
}
.margin-beneficio {
    text-align: left; 
    margin-right: -30px; 
    margin-left: 19px;
}

.btnsolicita :hover{
    color: rgba(24, 144, 255, 1) !important;
}

.img-video {
    width: 650px;
    height: 400px;
}