.catalago-componente {
    margin-top: 30px;
}
.texto-content {
    max-width: 1200px;
    margin: auto;
}
.buscador-content {
    max-width: 1200px;
    margin: auto;
}
.card-content {
    max-width: 1200px;
    margin: auto;
}

.img-catalogo {
    background-image: url('../images/fondo_2.svg'); 
    background-repeat: no-repeat;
    background-position: bottom;
    margin-bottom: 10px;
    background-position: 50% 116%;
}

.ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: #1890FF;
    border-color: #1890FF;
    color: white;
}

.ant-pagination .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: rgb(255 255 255 / 88%) !important;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, 
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    display: block;
    margin: auto;
    color: rgb(255 255 255 / 59%);
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    text-align: center;
    text-indent: 0.13em;
    opacity: 1;
    transition: all 0.2s;
}

.ant-modal {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Roboto ;
    pointer-events: none;
    position: relative;
    top: 100px;
    width: auto;
    max-width: calc(100vw - 32px);
    padding-bottom: 24px;
}

.ant-modal .ant-modal-content {
    position: relative;
    background-color: #03222A;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 24px;
}

.modal-filtro.ant-modal .ant-modal-header {
    background: #03222A;
    border-bottom: solid;
}

.modal-filtro.ant-modal .ant-modal-footer {
    border-top: solid;
    border-color: black;
    position: relative;
    bottom: -8px;
}

.ant-modal .ant-modal-header {
    background: #03222A; 
}

.ant-modal .ant-modal-body {
    margin-bottom: 20px;
}

.ant-modal .ant-modal-title {
    margin: 0;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.5;
    word-wrap: break-word;
    text-align: center;

}

.ant-modal .ant-modal-close {
    color: rgb(255 255 255);
}

.buscador-catalogo {
    background: rgb(255 255 255) !important;
    border-radius: 30px;
    width: 1138px !important;
    margin-left: 30px;
    margin-top: 30px;

    .ant-select-single .ant-select-selector {
        color: #000000 !important;
    }
    
}

.ant-select .ant-select-arrow {
    color: #ffffff;
    font-size: 14px;
    right: 4px !important;
    top: 17px !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #FFC000;
    border-radius: 30px;
}

.ant-select-dropdown 
.ant-select-item-empty {
    color:  #333f48;
}

.ant-select-dropdown {
    background-color:  rgb(255 255 255);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
}


.ant-pagination .ant-pagination-prev button, .ant-pagination .ant-pagination-next button {
    color: rgb(255 255 255);
}

.ant-badge .ant-badge-count {
    background: rgba(24, 144, 255, 1);
    box-shadow: 0 0 0 1px rgba(24, 144, 255, 1);
}

.btnFiltro {
    background-color: #000;
    color: #FFF; 
    border-color: #FFF;
}

.btnFiltro:hover {
    background-color: #0A5194;
    color: #FFF; 
    border-color: #0A5194;
}

.btnVerMas {
    background-color: #1890FF;
    color: #FFF; 
    border-color: #1890FF;
    outline: none;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    font-size: 14px;
    line-height: 1.5714285714285714;
    padding: 4px 15px;

    border-radius: 32px;
}

.btnVerMas:hover {
    background-color: #0A5194;
    color: #FFF; 
    border-color: #0A5194;
}

.paginacion {
    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: rgb(255 255 255);
        border-color: #FFF;
    }
    .ant-select-single .ant-select-selector {
        color: rgb(255 255 255 / 88%);
        border-color: #FFF;
    }

    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #d9d9d9 !important;
    }
}

.ant-form-item .ant-form-item-explain-error {
    font-weight: normal;
}

.ant-input-outlined:hover{
    background: #0A5194;
}

.ant-tabs-top >.ant-tabs-nav::before, .ant-tabs-bottom >.ant-tabs-nav::before, .ant-tabs-top >div>.ant-tabs-nav::before, .ant-tabs-bottom >div>.ant-tabs-nav::before {
    border-bottom: none;
}

.modal-filtro.ant-modal-root .ant-modal-centered .ant-modal {
    top: 20% !important;
}

.card-catalogo-mobile { &.ant-card {
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(6px);
    }
}


