/*.hazte-partner {
    max-width: 1200px;
    margin: auto;
}*/
.content-max{
    max-width: 1200px;
    margin: auto;
}
.card-max {
    max-width: 1200px;
    margin: auto;
}

.final-max {
    max-width: 1200px;
    margin: auto;
}

.content-partner {
    width: 50%;
    height: 80vh;
    margin-top: 30px;
    margin-left: -200px;
    position: relative;
    
}

.partner-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.partner-image {
    width: 100%;
    height: 100%;
}

.partner-card {
    position: absolute;
    top: 50%;
    right: 280px;
    transform: translate(50%, -50%);
    width: 67%;
    padding: 20px;
    height: 280px;
    //backdrop-filter: blur(20px);
    //background: rgb(0, 175, 179, 0.8);
    //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    //backdrop-filter: blur(1px);
    //-webkit-backdrop-filter: blur(1px);
}

.btnHaztePartner{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.ant-card {
        color: rgba(255, 255, 255, 1);
        //background: rgba(26, 31, 36, 0.4);
        background: rgba(36, 121, 121, 0.247);
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
    
}
.container-end {
    margin-top: 40px;
    height: 301px;
    background-color: rgba(26, 31, 36, 0.4);
    bottom: -60px;
    position: relative;
}

.footer-hazte-partner {
    margin-top: 20px !important;
}
.card-height {
    height: 500px;
    width: 280px;
}

@media (max-width: 1300px) { 
    .partner-image {
        width: 656px;
        height: 510px;
        margin-left: 303px;
        //margin-top: 110px;
    }

}

@media screen and (min-width: 1301px) and (max-width: 1600px) { 
    .partner-image {
        width: 856px;
        height: 657px;
        margin-left: 230px;
        margin-top: 30px;
    }

}

@media screen and (min-width: 1601px) and (max-width: 2000px) { 
    .partner-image {
        width: 856px;
        height: 657px;
        margin-left: 230px;
        margin-top: 34px;
    }

}

@media screen and (min-width: 2001px) and (max-width: 3600px) { 
    .partner-image {
        width: 856px;
        height: 657px;
        margin-left: 230px;
        margin-top: 230px;
    }

}
.margen-comienza {
    margin: 55px 17px 0px;
}

.btnHaztePartnerPage {
    margin-left: 10px;
}

