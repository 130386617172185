@media screen and (min-width: 350px) and (max-width: 390px) {
    .logo {
        margin-right: 50px !important;
        margin-top: 3px;
        right: 15px;
        position: relative;
    }
    .botones-inicio {
        margin-top: 23px !important;
        margin-left: 0px !important;
    }
    .tarjetascaracteristicas {
        margin-top: 15px !important;
    }
    .cards-caracteristicas {
        width: 345px !important;
    }
    .card-catalogo-mobile {
        //height: 236px !important;
        height: auto !important;
    }
    .content-partner-mobile {
        //height: 63vh !important;
    }
    .card-height {
        //height: 480px !important;
        width: 100% !important;
        height: auto !important;
        margin-right: 15px;
        margin-left: 15px;
    }
    .content-contacto {
        height: 47vh !important;
    }
    .details-mobile {
        margin-top: 20px !important;
    }

    
}
@media (max-width: 365) {
    .tarjetascaracteristicas {
        margin-top: 12px !important;
    }
    .cards-caracteristicas {
        width: 282px !important;
    }
}
@media (max-width: 768px) { 
    .logo {
        margin-right: 120px;
        margin-top: 3px;
        right: 15px;
        position: relative;
    }
    .ant-layout-header {
        color: rgb(255 255 255 / 88%);
    }

    .ant-drawer .ant-drawer-content {
        background: #1a1f24 !important;
    }

    .video-mobile {
        width: 380px !important;
    }

    .html5-video-player {
        width: 382px !important;
    }

    .div-detalle {
        padding-left: 21px !important;
    }

    .content-contacto {
        text-align: center;
      }

      .title {
        width: 100%;
      }

      .subtitle {
        font-size: 20px;
        display: block;
      }

        .details-mobile {
          margin: 80px 0px 0;
        
      }

    .card-formulario {
        border: 1px solid #0E8DA6 !important;
        color: #fff !important;
        font-weight: 900 !important;
        font-size: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .card-faq-mobile{
        margin-right: 20px;
    }
    .content-contacto {
        height: 34vh;
    }
    .card-catalogo-mobile {
        //height: 185px !important;
        height: auto !important;
    }
    .tab-descripcion {
        margin-left: -6px !important;
        margin-right: -25px !important;
    }
    .tab-esquema {
        margin-left: -6px !important;
        margin-right: -25px !important;
    }
    .descripcion-detalle {
        margin-left: 10px !important;
        padding-right: 22px !important;
    }

    .ant-carousel .slick-dots-bottom {
        bottom: -36px;
    }
    
    .btnHaztePartner {
        margin-top: 10px;
    }
    .titulo {
        margin-top: 5px;
    }
    .detalle-beneficio {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .btnsolicita {
        margin-top: 20px;
    }
    .btnsolicita :hover{
        color: rgba(24, 144, 255, 1) !important;
    }
    .btnfolleto :hover {
        color: rgba(24, 144, 255, 1) !important; 
    }

    .descripcion-texto-hpartner {
        text-align: center !important;
    }

    .video {
        margin-top: 120px;
        margin-left: 30px;
        margin-right: 30px !important;
    }

    .footer-margin {
        margin-left: -17px;
    } 
    .link-catalogo {
        margin-left: 0
    }
    .link-contacto {
        margin-left: -0
    }
    .margin-beneficio {
        text-align: center; 
        margin-right: 40px; 
        margin-left: 20px;
    }
    .redes-sociales {
        padding-left: 7px !important;
        margin-top: 10px;
    }
    .textos-layotu {
        margin-top: 40px;
        color: #fff;
        margin-left: 37px;
        margin-right: 37px;
    }
    .footer-mobile {
        margin-left: -20px !important;
    }
    .footer-content {
        margin-left: -26px;
        margin-right: -22px;
        text-align: center;
    }

    /*.ant-form-item .ant-form-item-label > label {
        margin-left: -10px;
    }*/

    /*.top-mobile {
        margin-top: -35px;
        &.ant-form-item-with-help .ant-form-item-explain {
            margin-top: -25px;
        }
    }*/

    .espacio {
        margin-top: 18px;
    }

    .line {
        line-height: 1;
    }

    .buscador-catalogo {
        margin-top: 20px;
        width: 332px !important;
        margin-left: 29px;
    }

    .img-inicio {
        background-position: 50% 37%;
    }

    .content-partner {
        width: 50%;
        height: 80vh;
        margin-top: 30px;
        margin-left: -200px;
        position: relative;
        
    }

    .partner-card {
        position: absolute;
        top: 38%;
        right: 192px;
        transform: translate(50%, -50%);
        width: 103%;
        padding: 0;
        height: 280px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .partner-image {
        //width: 96%;
        margin-top: 20px;
        margin-left: 1px;
        width: 385px;
        height: 503px;
    }

    .margen-mobile {
        margin-top: 10px !important;
    }

    .img-video {
        width: 300px;
        height: 200px;
    }
    .btnVerMas {
        width: 200px;
    }

    .card-height {
        //height: 480px !important;
        width: 100% !important;
        height: auto !important;
        margin-right: 15px;
        margin-left: 15px;
    }

    .btnHaztePartnerPage {
        margin-top: 10px;
        margin-left: 0px;
    }

    .margen-comienza {
        margin: 26px 33px 0px;
    }

}

@media (max-width: 768px) and (orientation: landscape) {
    .content-layout {
        height: 130vh;
    }

    .tarjetascaracteristicas {
        padding-left: 10px;
        padding-right: 0;
        position: relative;
        margin: auto;
        margin-top: 20px;
        will-change: transform;
    }

    .cards-caracteristicas {
        width: 300px;
    }
}