

    $background          : #1A1F24;
    $linkmenu            : #FFC000;
    $backgroundfooter    : #34383C;
    $backgroundpage      : #053743;
    $errormessage        : #FF0101;
    $icons               : #06EFFE;
    $text1               : #fff;
    $text2               : #FFC000;

    $weight1            : 100;
    $weight2            : 200;
    $weight3            : 300;
    $weight4            : 400;
    $weight7            : 700;
    $weight9            : 900;

    body {
        font-family: 'Roboto' !important;
        background-color: $background;
        
    }

    .title {
        color: $text1 !important;
        font-weight: $weight7 !important;
        font-size: 35px !important;
        font-family: 'Roboto';
    }
    .subtitle {
        color: $text1 !important;
        font-weight: $weight4 !important;
        font-size: 18px !important;
        margin: 0 40px;
        font-family: 'Roboto';
    }

    ::-webkit-scrollbar {
        height: 12px !important;
        width: 15px  !important;
        background: #1A1F24  !important;
      }
      
      
    ::-webkit-scrollbar-thumb {
        background: rgb(6, 239, 254) !important;
        border-radius: 1ex !important;
        border-style: solid !important;
        border-width: 2px !important;
      }
        
    ::-webkit-scrollbar-track {
        border-radius: 0px !important;
        background: #1A1F24  !important;
    }
    
    .scroll-animation {
        animation: scrollDown 0.5s ease;
    }

    .ant-menu-dark .ant-menu-item, .ant-menu-dark>.ant-menu .ant-menu-item, .ant-menu-dark .ant-menu-submenu-title, .ant-menu-dark>.ant-menu .ant-menu-submenu-title {
        color: rgb(255 255 255) !important;
    }

    :where(.css-dev-only-do-not-override-1k979oh).ant-menu {
        font-size: 14px;
    }

    .paginacion .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
        pointer-events: none;
    }

    .paginacion .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
        display: none !important;
    }