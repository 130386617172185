.como-funciona {
    max-width: 1200px;
    margin: auto;
}

.half {
    display: flex;
    height: 100%;
}
  
.left-half,
.right-half {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
  
.left-half img {
    width: 100%;
}
  
.right-half {
    background-color: rgba(0, 175, 179, 0.08); 
    padding: 20px; 
    border-radius: 0 30px 30px 0;
    bottom: 6px;
    position: relative;
}

.dot-documentacion{
    top: 23% !important;
}

.custom-dots-container {
    position: absolute;
    top: 40%;
    right: 21px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  
    .custom-dot {
      width: 10px;
      height: 10px;
      margin: 5px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.75);
      cursor: pointer;
      transition: background-color 0.3s;
  
      &.active {
        background-color: white;
        border-color: white;
      }
    }
}

.customCarousel .ant-carousel .slick-dots {
    display: none !important;
}

.card-carrousel {
    
    &.ant-card .ant-card-body {
      padding: 0;
    }
}
